import React, { useState, useCallback } from "react"
import { Link } from "gatsby"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"

import Layout from "../../components/layout"
import FooterCaption from "../../components/footer-caption"

import One from "../../../content/assets/furniture/1.jpg"
import Two from "../../../content/assets/furniture/2.jpg"
import Three from "../../../content/assets/furniture/3.jpg"
import Four from "../../../content/assets/furniture/4.jpg"

const Furniture = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const photos = [
    {
      src: One,
      width: 1.6,
      height: 1,
      caption: `Rustic Coat Rack`,
    },
    {
      src: Two,
      width: 1,
      height: 1.7,
      caption: `Guitar Stand - Iron & Leather. Designed by Sam Nietfeld and Published in the ABANA "Anvil's Ring" magazine Spring 1999 Vol. 26 No. 4 page 27.`,
    },
    {
      src: Three,
      width: 1.1,
      height: 1.6,
      caption: `Guitar Stand - Iron & Leather. Designed by Sam Nietfeld and Published in the ABANA "Anvil's Ring" magazine Spring 1999 Vol. 26 No. 4 page 27.`,
    },
    {
      src: Four,
      width: 1.6,
      height: 0.8,
      caption: `Small Hooks w/Forged Nails (or Modern Screws if you perfer)`
    }
  ]

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  return (
    <Layout>
      <article>
        <h1>Household & Furniture</h1>
        <Gallery photos={photos} onClick={openLightbox} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                components={{ FooterCaption }}
                currentIndex={currentImage}
                views={photos.map(x => ({
                  ...x,
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        <Link
          style={{ display: "inline-block", marginTop: "24px" }}
          to="/gallery"
        >
          ← Back to Gallery
        </Link>
      </article>
    </Layout>
  )
}

export default Furniture
